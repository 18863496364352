import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby-link";

import { Context } from "../store";
import { addError, useNotificationStore } from "../../utils/errors";
import WaitingAnimation from "../WaitingAnimation";
import { removeLocalStorageItem } from "../../_services/localStorageService";
import { authenticationService } from "../../_services/authenticationService";

const Logout = () => {
  const [state] = useState({ logged_out: false });

  useEffect(() => {
    async function logout() {
      authenticationService.logout().catch((err) => {
        addError("Could not contact the server for logout");
      });

      removeLocalStorageItem("currentUser");
      // localStorage.removeItem("currentUser")
      navigate("/");
    }
    logout();
  }, []);

  return <div>{!state.logged_out ? <WaitingAnimation /> : null}</div>;
};

export default Logout;
